
import { DataGame_GMSearchDailyDataStatistic } from '@/apis/data_game';
import MixedChart from '@/components/charts/MixedChart.vue';
import { GetItemName } from '@/constant/itemconfig';
import { formatJson } from "@/utils";
import { exportJson2Excel } from "@/utils/excel";
import { TodayEnd, TodayStart } from "@/utils/timer";
import { SeriesOption } from 'echarts';
import { ElForm, ElMessage } from 'element-plus';
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    components: {
        MixedChart,
    },
    setup() {
        const { t } = useI18n()
        const dataForm = ref<typeof ElForm>()
        const dataMap = reactive({
            time: Date,
            tableKey: 0,
            list: [] as ITServerZoneDailyDataStatisticItem[],
            sellitem: [] as { itemconfigid: string, itemcount: number, index: number }[],
            total: 1,
            listLoading: true,
            todayPlayerOnline: 0,
            starttime: TodayStart(),
            endtime: TodayEnd(),
            listQuery: {
                itemconfigid: "",
                label: "Day",
                sort: "+id"
            },
            xAxisOptions: [] as string[],
            xChartData: {} as { [k: string]: Partial<SeriesOption & { sortid: number }> },
            labelOptions: ["Day", "Week", "Month"],
            downloadLoading: false,
            dialogFormVisible: false,
        })

        const getDataList = async () => {
            dataMap.listLoading = true;
            dataMap.list = [];
            dataMap.sellitem = [];
            let msg = {} as IC2G_GMSearchDailyDataStatistic;
            msg.StartTime = Math.ceil(dataMap.starttime.getTime() / 1000);
            msg.EndTime = Math.ceil(dataMap.endtime.getTime() / 1000);
            let cbmsg = await DataGame_GMSearchDailyDataStatistic(msg);
            if (cbmsg?.Error !== 0) {
                ElMessage.error(`GetLogFail: ${cbmsg?.Message}`);
                return;
            }
            if (cbmsg.SearchResult) {
                const temp = {} as { [k: string]: number };
                for (let k of cbmsg.SearchResult) {
                    let record = JSON.parse(k) as ITServerZoneDailyDataStatisticItem;
                    record.Id = record._id;
                    dataMap.list.push(record);
                    for (let sellinfo of record.ShopSellItem) {
                        temp[sellinfo[0] + ""] = temp[sellinfo[0] + ""] || 0;
                        temp[sellinfo[0] + ""] += sellinfo[1];
                    }
                }
                for (let itemconfigid in temp) {
                    dataMap.sellitem.push({ itemconfigid: itemconfigid, itemcount: temp[itemconfigid], index: 0 })
                }
                dataMap.sellitem.sort((a, b) => { return a.itemcount - b.itemcount });
                dataMap.sellitem.forEach((v, index) => {
                    v.index = index + 1
                })
                dataMap.list.sort((a, b) => {
                    return a.Year * 365 + a.Month * 30 + a.Day - (b.Year * 365 + b.Month * 30 + b.Day);
                })
            }
            if (cbmsg.Message) {
                let recordToday = JSON.parse(cbmsg.Message) as ITServerZoneDailyDataStatisticItem;
                dataMap.todayPlayerOnline = recordToday.HoursPlayerOnline.reduce((a, b) => a + b, 0) || 0;
            }

            dataMap.total = cbmsg.SearchCount;
            dataMap.listLoading = false;
        }
        const GetRowItemName = (val: string|number) => {
            return GetItemName(val)
        }
        const handleChange = (val: string) => {
            dataMap.xAxisOptions = [];
            dataMap.xChartData = {};
            const itemconfigid = dataMap.listQuery.itemconfigid;
            let vdata = [] as number[];
            if (val === "Day") {
                dataMap.list.forEach(v => {
                    let daycount = 0;
                    for (let _v of v.ShopSellItem) {
                        if (_v[0] + "" == itemconfigid) {
                            daycount = _v[1];
                            break;
                        }
                    }
                    dataMap.xAxisOptions.push(`${v.Year}/${v.Month} ${v.Day}号`);
                    vdata.push(daycount);
                })
            }
            else if (val === "Week") {
                let weekindex = 1;
                const weekcount = {} as { [k: string]: number };
                dataMap.list.forEach(v => {
                    if (v.Day < 8) {
                        weekindex = 1;
                    }
                    else if (v.Day < 15) {
                        weekindex = 2;
                    }
                    else if (v.Day < 22) {
                        weekindex = 3;
                    }
                    else {
                        weekindex = 4;
                    }
                    const Xkey = `${v.Year}/${v.Month} ${weekindex}周`;
                    weekcount[Xkey] = weekcount[Xkey] || 0;
                    for (let _v of v.ShopSellItem) {
                        if (_v[0] + "" == itemconfigid) {
                            weekcount[Xkey] += _v[1];
                            break;
                        }
                    }
                    if (dataMap.xAxisOptions.includes(Xkey) === false) {
                        dataMap.xAxisOptions.push(Xkey);
                    }
                })
                dataMap.xAxisOptions.forEach(v => {
                    vdata.push(weekcount[v]);
                })
            }
            else if (val === "Month") {
                const monthcount = {} as { [k: string]: number };
                dataMap.list.forEach(v => {
                    const Xkey = `${v.Year}-${v.Month}`;
                    monthcount[Xkey] = monthcount[Xkey] || 0;
                    for (let _v of v.ShopSellItem) {
                        if (_v[0] + "" == itemconfigid) {
                            monthcount[Xkey] += _v[1];
                            break;
                        }
                    }
                    if (dataMap.xAxisOptions.includes(Xkey) === false) {
                        dataMap.xAxisOptions.push(Xkey);
                    }
                })
                dataMap.xAxisOptions.forEach(v => {
                    vdata.push(monthcount[v]);
                })
            }
            dataMap.xChartData[`${GetRowItemName(itemconfigid)}[${itemconfigid}]`] = {
                data: vdata,
            }
        }
        const handleLookUpChange = (row: { itemconfigid: string, itemcount: number, index: number }) => {
            dataMap.dialogFormVisible = true;
            dataMap.listQuery.itemconfigid = row.itemconfigid;
            handleChange("Day")
        }

        const handleDownload = () => {
            dataMap.downloadLoading = true;
            const tHeader = ["Time", "Year", "Month", "Day", "ServerID", "HoursPlayerNew", "HoursPlayerOnline", "HoursBattleCount", "OrderIncome", "ShopSellItem"];
            const filterVal = ["Time", "Year", "Month", "Day", "ServerID", "HoursPlayerNew", "HoursPlayerOnline", "HoursBattleCount", "OrderIncome", "ShopSellItem"];
            const data = formatJson(filterVal, dataMap.list);
            exportJson2Excel(tHeader, data, "DataPlayerOnline");
            dataMap.downloadLoading = false;
        }

        onMounted(() => {
            getDataList()
        })

        return {
            t, ...toRefs(dataMap),
            dataForm,
            getDataList,
            handleChange,
            GetRowItemName,
            handleDownload,
            handleLookUpChange
        }
    }
})

